
import axios from 'axios';
export default {
    
    async createItemColors(params)  {
        return await axios.post(`item_colors/create` , params)
    },
    async createItemColorsList(params)  {
        return await axios.post(`item_colors/create/list` , params)
    },
    async updateItemColorsColumn(column , value , data)  {
        return await axios.put(`item_colors/update_list?${column}=${value}` , data)
    },
    async deleteItemColorsList(list)  {
        return await axios.delete(`item_colors/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportItemColors(column , value)  {
        return await axios.get(`item_colors/report?${column}=${value}`)
    },
    async getAllItemColors()  {
        return await axios.get(`item_colors/all`)
    },
    async getOneItemColors(item_color_id)  {
        return await axios.get(`item_colors/all/${item_color_id}`)
    },
    async getItemColorsByColumn(column , value)  {
        return await axios.get(`item_colors/filter?column=${column}&value=${value}`)
    },
    async deleteItemColors(item_color_id)  {
        return await axios.delete(`item_colors/delete/${item_color_id}`)
    },
    async updateItemColors(item_color_id , params)  {
        return await axios.put(`item_colors/update/${item_color_id}` , params)
    }
}